import React from "react"
import OneTwoBox from "../../bosom/components/layout/boxes/one-two-box/OneTwoBox";
import HealthHeroSvg from "./assets/HealthHeroSvg";

const HealthNotice = (props) => {
    const pStyle = {'margin': "0.5rem 0 0 0"};
    const hStyle = {'margin': "1.5rem 0 0 0"};

    return (
        <OneTwoBox svg={<HealthHeroSvg/>}
                   title={"Health Notice"}
                   description={"The alsTherapy.io services were developed by a diverse and multidisciplinary group of professionals. However, alsTherapy.io is not a medical organization and we can not give you medical advice, diagnoses, treatment, or cure. We strongly urge you to consult with your primary health care provider (e.g., physician or nurse practitioner) before using our site, products, or services offered on this site."}>

            <p>ALS causes physical changes that should be medically monitored. In addition:</p>

            <ul>
                <li style={hStyle}>PALS should avoid high-resistance exercise</li>
                <li style={pStyle}>Do not exercise muscles that do not have anti gravity strength. Avoid eccentric
                    exercise.
                </li>
                <li style={pStyle}>Perform under supervision to avoid the risk of injury or accident.</li>
                <li style={pStyle}>Communicate comfort and pain levels continuously to professionals and caregivers</li>
                <li style={pStyle}>Do not over do it. Allow your body to rest between exercises.</li>
                <li style={pStyle}>Do not perform physical activity if you feel pain or discomfort in targeted area of
                    the body.
                </li>
                <li style={pStyle}>Encourage regular stretching and range-of-motion exercises early in the course of
                    disease.
                </li>
                <li style={pStyle}>Caregiver participation is needed when muscle weakness prevents the patient from
                    performing program independently.
                </li>
            </ul>

            <p>AlsTherapy.io is not a medical organization and we do not take responsibility for individual rehabilitation. Our services are meant to be used as a self directed home care and treatment not as a substitute to any medical / professional care. Please consult your doctor before using alsTherapy.io. Please take note of the conclusion bellow:</p>

            <blockquote cite={"https://pubmed.ncbi.nlm.nih.gov/24510737/"}>
                ALS is a progressive, fatal disease, and PALS have multiple complex, evolving needs. At each stage of
                the disease, an experienced rehabilitation team is well positioned to make a significant impact on the
                life of PALS by maximizing safe mobility and independence, minimizing disease-related symptoms,
                facilitating communication and informed decision making, and enhancing quality of life.
                <aside>
                    <a href="https://pubmed.ncbi.nlm.nih.gov/29924018/">Majmudar S, Wu J, Paganoni S. Rehabilitation in
                        amyotrophic lateral sclerosis: why it matters. Muscle Nerve. 2014 Jul;50(1):4-13. doi:
                        10.1002/mus.24202. Epub 2014 May 17. PMID: 24510737; PMCID: PMC4433000.</a>
                </aside>
            </blockquote>

        </OneTwoBox>
    )
}

export default HealthNotice;
